// src/App.js
import React from 'react';
import HomePage from './pages/HomePage'; // Import the HomePage component

function App() {
  return (
    <div className="App">
      <HomePage /> {/* Set HomePage as the root */}
    </div>
  );
}

export default App;
