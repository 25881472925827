import React, { useState, useEffect } from 'react';
import { Box, Text, Image, Grid, Input, Button, Textarea, Icon, VStack, Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import { FiCamera, FiTrash2 } from 'react-icons/fi';  // Import camera and trash icons
import Header from '../components/Header';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

axios.defaults.baseURL = 'https://funkbo.se'; // Your domain, use relative paths if the same domain

const HomePage = () => {
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null); // Preview image URL
  const [text, setText] = useState('');
  const [uploading, setUploading] = useState(false); // to handle upload status
  const [selectedImageId, setSelectedImageId] = useState(null); // For delete modal

  // Modal control
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Fetch previously uploaded content from the backend when the component mounts
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get('/api/content'); // Fetch all previously uploaded content
        const content = response.data;

        // Separate the image URLs and text content from the response
        const imageUrls = content.map(item => ({ id: item.id, url: item.imageUrl }));
        const texts = content.map(item => item.textInput);

        // Update the state to display the previously uploaded content
        setImages(imageUrls);
        setTexts(texts);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };

    fetchContent(); // Fetch the content when the component mounts
  }, []); // Empty dependency array ensures this runs once when the component mounts

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setPreviewImage(URL.createObjectURL(file)); // Set preview image
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!image || !text) {
      alert('Vänligen ladda upp en bild och ange en beskrivning.');  // Swedish message
      return;
    }

    // Compress the image before uploading
    const options = {
      maxSizeMB: 1,  // Max file size (MB)
      maxWidthOrHeight: 1920,  // Max width/height
      useWebWorker: true,  // Use web worker for faster compression
    };

    try {
      setUploading(true); // Set uploading state

      // Compress image
      const compressedImage = await imageCompression(image, options);

      const formData = new FormData();
      formData.append('image', compressedImage);  // Use the compressed image
      formData.append('text', text);

      const response = await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Backend response should include image URL and text
      const { imageUrl, uploadedText, id } = response.data;

      // Update the feed with the new image and text
      setImages((prevImages) => [{ id, url: imageUrl }, ...prevImages]);  // Add new image URL to the list
      setTexts((prevTexts) => [uploadedText, ...prevTexts]);  // Add new text to the list

      // Clear form fields
      setImage(null);
      setPreviewImage(null);  // Clear the preview image
      setText('');

      alert('Bild och text har laddats upp!');  // Swedish success message
    } catch (error) {
      console.error('Error uploading the image:', error);
      alert('Misslyckades med att ladda upp bilden och texten. Försök igen.');  // Swedish error message
    } finally {
      setUploading(false); // Reset uploading state
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/delete/${selectedImageId}`);
      setImages(images.filter(image => image.id !== selectedImageId)); // Remove image from state
      setSelectedImageId(null);
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error deleting the image:', error);
    }
  };

  const openDeleteModal = (id) => {
    setSelectedImageId(id); // Set selected image ID for deletion
    onOpen(); // Open confirmation modal
  };

  return (
    <Box>
      <Header />
      <Box mt="100px" px={6} py={10}>
        {/* Upload form */}
        <VStack spacing={5} mb={8} as="form" onSubmit={handleSubmit} align="center">
          {/* File input styled with an icon */}
          <Button
            leftIcon={<Icon as={FiCamera} />}
            colorScheme="brand"
            variant="solid"
            size="lg"
            as="label"  // This lets us style the button like a file input
            cursor="pointer"
            bgGradient="linear(to-r, #eeaeca, #94bbe9)"  // Use gradient from theme.js
            _hover={{
              bgGradient: 'linear(to-r, #94bbe9, #eeaeca)',  // Reverse gradient on hover
            }}
          >
            Ladda upp en bild  {/* Swedish text for "Upload a Photo" */}
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              mb={4}
              required
              hidden
              isDisabled={uploading} // Disable form when uploading
            />
          </Button>

          {/* Image Preview */}
          {previewImage && (
            <Image
              src={previewImage}
              alt="Förhandsvisning av bilden"  // Swedish for "Image preview"
              borderRadius="md"
              boxShadow="md"
              objectFit="cover"
              maxW="400px"
              maxH="300px"
              mb={4}
            />
          )}

          <Textarea
            placeholder="Ange en beskrivning..."  // Swedish for "Enter a description..."
            value={text}  // Value is tied to the `text` state
            onChange={handleTextChange}  // This updates the `text` state
            mb={4}
            size="lg"
            resize="vertical"
            required
            isDisabled={uploading}  // Disable during upload
          />

          {/* Upload button with the same gradient as the picture button */}
          <Button
            type="submit"
            size="lg"
            bgGradient="linear(to-r, #eeaeca, #94bbe9)"  // Same gradient as photo upload button
            color="white"
            isLoading={uploading}
            loadingText="Laddar upp..."  // Swedish for "Uploading..."
            _hover={{
              bgGradient: 'linear(to-r, #94bbe9, #eeaeca)',  // Reverse gradient on hover
            }}
          >
            Ladda upp inlägg  {/* Swedish text for "Upload Post" */}
          </Button>

          {/* Show spinner while uploading */}
          {uploading && <Spinner size="xl" color="brand.500" mt={4} />}
        </VStack>

        {/* Feed of uploaded images and texts */}
        <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={6}>
          {images.map((image, index) => (
            <Box
              key={index}
              position="relative"
              bg="white"
              p={6}
              borderRadius="lg"
              boxShadow="md"
              borderWidth="1px"
              borderColor="gray.200"
              transition="all 0.2s"
              _hover={{ boxShadow: 'lg', transform: 'scale(1.02)' }}
            >
              {/* Delete Icon */}
              <Icon
  as={FiTrash2}
  position="absolute"
  top="10px"
  right="10px"
  boxSize={6}
  cursor="pointer"
  bgGradient="linear(to-r, #eeaeca, #94bbe9)"  // Apply gradient to icon
  color="transparent"  // Make the color transparent so the gradient shows
  bgClip="text"  // Clip the gradient into the text (icon)
  onClick={() => openDeleteModal(image.id)} // Open the delete modal
/>

              <Image
                src={image.url}
                alt={`Uppladdad bild ${index + 1}`}  // Swedish for "Uploaded Image"
                mb={4}
                borderRadius="md"
                objectFit="cover"
              />
              <Text
                fontSize="lg"
                color="gray.700"
                fontWeight="medium"
                textAlign="center"
              >
                {texts[index]}
              </Text>
            </Box>
          ))}
        </Grid>

        {/* Delete Confirmation Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Delete Image</ModalHeader>
            <ModalCloseButton />
            <ModalBody color="black">
  Are you sure you want to delete this image?
</ModalBody>

            <ModalFooter>
            <Button
  bgGradient="linear(to-r, #eeaeca, #94bbe9)"  // Apply gradient
  color="white"
  _hover={{
    bgGradient: 'linear(to-r, #94bbe9, #eeaeca)',  // Reverse gradient on hover
  }}
  onClick={handleDelete}
>
  Yes, Delete
</Button>

<Button
  ml={3}
  bgGradient="linear(to-r, #eeaeca, #94bbe9)"  // Apply gradient
  color="white"
  _hover={{
    bgGradient: 'linear(to-r, #94bbe9, #eeaeca)',  // Reverse gradient on hover
  }}
  onClick={onClose}
>
  No, Cancel
</Button>

            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default HomePage;
