import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import Sparkles from 'react-sparkle';

const Header = () => {
  return (
    <Box
      as="header"
      position="fixed"
      top="0"
      width="100%"
      zIndex="1000"
      bg="transparent"
    >
      <Flex align="center" justify="center" py={4}>
        <Box position="relative">
          {/* Sparkling effect behind the text */}
          <Sparkles
            color={['#eeaeca', '#94bbe9']}  // Gradient colors for sparkles
            count={40}  // Number of sparkles
            fadeOutSpeed={20}  // Adjust fade-out speed
            flicker={false}  // Disable flickering for smoother effect
            overflowPx={30}  // How far outside the text sparkles can appear
            minSize={5}
            maxSize={10}
          />

          <Heading
            as="h1"
            size="2xl"
            color="white"
            textAlign="center"
            textShadow="2px 2px 4px rgba(0, 0, 0, 0.7)"
            zIndex="1"  // Ensure text is on top of sparkles
          >
            Fuengirola
          </Heading>
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
