// src/theme.js
import { extendTheme } from '@chakra-ui/react';
import '@fontsource/jost/400.css'; // Import 'Jost' font weights as needed
import '@fontsource/jost/700.css';

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        background: 'linear-gradient(to bottom, #eeaeca, #94bbe9)', // Gradient background
        color: 'eggwhite', // Text color
        minHeight: '100vh',
      },
    },
  },
  fonts: {
    heading: `'Jost', sans-serif`,
    body: `'Jost', sans-serif`,
  },
  colors: {
    eggwhite: '#f0ead6', // Define 'eggwhite' color
    black: '#000000',
    brand: {
      50: '#e9d8fd',
      100: '#d6bcfa',
      200: '#b794f4',
      300: '#9f7aea',
      400: '#805ad5', // Purple shade
      500: '#6b46c1',
      600: '#553c9a',
      700: '#44337a',
      800: '#322659',
      900: '#21183c',
    },
  },
});

export default theme;
